import { isEqual } from 'lodash';
import * as React from 'react';
import type {
  SizeModel,
  SizeModelConfigurable,
} from '~source/core/models/components/atoms/size';
import type { ProductDetails } from '~source/core/models/components/organisms/product-details';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';

type Sizes = Record<
  string,
  {
    name: string;
    sizes: SizeModel[] | SizeModelConfigurable[];
  }
>;

export default function useSizes(sizes: ProductDetails['sizes']) {
  const t = useTranslate();

  const [currentSelectedSizeId, setCurrentSelectedSizeId] = React.useState<
    number | null
  >(null);
  const [selectedSizeIds, setSelectedSizeIds] = React.useState<number[]>([]);
  const [error, setError] = React.useState('');

  const idle = React.useRef(true);

  const selectedSizes = React.useMemo(
    () => sizes?.filter((size) => selectedSizeIds.includes(size.id)) ?? [],
    [sizes, selectedSizeIds],
  );

  const selectedSize = React.useMemo(
    () => sizes?.find((size) => size.id === currentSelectedSizeId) ?? null,
    [sizes, currentSelectedSizeId],
  );

  const sizeGroups = React.useMemo(() => {
    if (!sizes?.length) return null;

    return Object.values(
      (sizes as SizeModel[]).reduce((acc: Sizes, size, i) => {
        // Handle bundle sizes
        if ('productBundleLineId' in size) {
          const { productBundleLineId } = size;

          acc[productBundleLineId] = {
            name: size.name || t('SIZES'),
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            sizes: [...(acc[productBundleLineId]?.sizes || []), size],
          };

          return acc;
        }
        // Handle configurable sizes
        return { [i]: { name: t('SIZES'), sizes } };
      }, {}),
    );
  }, [sizes, t]);

  const validate = React.useCallback(() => {
    if (sizes?.length && !selectedSizeIds.length) {
      setError('ERROR_SELECT_SIZE');
      return false;
    }

    if (sizes?.length && !isEqual(selectedSizeIds.length, sizeGroups?.length)) {
      setError('ERROR_SELECT_MISSING_SIZE');
      return false;
    }

    setError('');
    return true;
  }, [selectedSizeIds, sizes, sizeGroups]);

  React.useEffect(() => {
    if (idle.current) {
      setError('');
      idle.current = false;
      return;
    }
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setError('');

    if (!selectedSizeIds.length) {
      idle.current = true;
    }
  }, [selectedSizeIds]);

  const reset = () => {
    idle.current = true;
    setSelectedSizeIds([]);
  };

  const handleSizeSelection = (sizeId: number | null) => {
    setCurrentSelectedSizeId(sizeId);
    setSelectedSizeIds((prevSelectedSizes) => {
      if (!sizeId || !sizes?.length) return prevSelectedSizes;

      const size = sizes.find((s) => s.id === sizeId);
      if (!size) return prevSelectedSizes;

      // Handle bundle sizes
      if ('productBundleLineId' in size) {
        const { productBundleLineId } = size;
        const existingSizeIds = prevSelectedSizes.filter(
          (id) =>
            (sizes as SizeModel[]).find((s) => s.id === id)
              ?.productBundleLineId !== productBundleLineId,
        );
        return [...existingSizeIds, sizeId];
      }

      // Handle configurable size
      return [sizeId];
    });
  };

  const getSelectedSizeId = React.useCallback(
    (sizeGroup: (SizeModel | SizeModelConfigurable)[]) =>
      selectedSizeIds.find((selectedSizeId) =>
        sizeGroup.some((size) => size.id === selectedSizeId),
      ) ?? null,
    [selectedSizeIds],
  );

  return {
    selectedSizeIds,
    selectedSizes,
    selectedSize,
    sizeGroups,
    handleSizeSelection,
    getSelectedSizeId,
    error,
    validate,
    reset,
  };
}

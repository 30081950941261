import * as React from 'react';
import useTimer from './useTimer';

export default function useAddedMessage() {
  const [isAddedMessageVisible, setIsAddedMessageVisible] =
    React.useState(false);
  const { setTimer, cancelTimer, isTimerActive } = useTimer(5000);

  const showAddedMessage = () => setIsAddedMessageVisible(true);

  const hideAddedMessage = () => {
    cancelTimer();
    setIsAddedMessageVisible(false);
  };

  React.useEffect(() => {
    if (isAddedMessageVisible && !isTimerActive) setTimer();
    return () => cancelTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddedMessageVisible]);

  React.useEffect(() => {
    if (!isTimerActive) hideAddedMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimerActive]);

  return {
    isAddedMessageVisible,
    showAddedMessage,
    hideAddedMessage,
  };
}

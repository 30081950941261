import cx from 'classnames';
import { FunctionComponent, ReactElement } from 'react';
import $ from './error-message.module.scss';

interface Props {
  message: string | ReactElement | string[] | ReactElement[];
  title?: string | ReactElement;
  type: 'error' | 'warn' | 'invalid';
}

const ErrorMessage: FunctionComponent<Props> = (props) => {
  const { message, type, title } = props;

  return (
    <div
      role="alert"
      aria-live="assertive"
      className={cx(
        $.wrap,
        type === 'warn' && $.wrapGrey,
        type === 'invalid' && $.wrapInvalid,
      )}
    >
      <span className={$.icon} />
      {Array.isArray(message) && (
        <div className={$.listWrap}>
          {title && <h2 className={$.title}>{title}</h2>}
          <ul className={$.list}>
            {message.map((error) => (
              <li
                key={typeof error === 'string' ? error : error.key}
                className={$.item}
              >
                {error}
              </li>
            ))}
          </ul>
        </div>
      )}
      {!Array.isArray(message) && <p className={$.message}>{message}</p>}
    </div>
  );
};

export default ErrorMessage;

import constrain from './constrain';

/**
 * Re-maps a number from one range to another.
 * @author TessavWalstijn. GitHub: https://github.com/TessavWalstijn
 * @param  {Number} input  the incoming value to be converted
 * @param  {Number} start1 lower bound of the value's current range
 * @param  {Number} stop1  upper bound of the value's current range
 * @param  {Number} start2 lower bound of the value's target range
 * @param  {Number} stop2  upper bound of the value's target range
 * @param  {Boolean} [withinBounds] constrain the value to the newly mapped range
 * @return {Number}
 */
const maprange = (
  input: number,
  start1: number,
  stop1: number,
  start2: number,
  stop2: number,
  withinBounds = false,
): number => {
  const newval =
    ((input - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
  if (!withinBounds) {
    return newval;
  }
  if (start2 < stop2) {
    return constrain(newval, start2, stop2);
  }
  return constrain(newval, stop2, start2);
};

export default maprange;

import React from 'react';
import { SwiperSlide } from 'swiper/react';
import { ProductsList } from '~source/core/models/components/organisms/products-list';
import { sendAnalyticsOfItemList } from '~source/core/services/e-commerce/e-commerce';
import Container from '~source/ui/components/containers/container/container';
import ProductBox from '~source/ui/components/molecules/product-box/product-box';
import SwiperCarousel from '~source/ui/components/molecules/swiper/swiper-carousel';
import useBreakpoints from '~source/ui/hooks/ui/useBreakpoints/useBreakpoints';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './products-swiper.module.scss';

interface Props extends ProductsList {
  nested?: boolean;
}

const ProductsSwiper: React.FC<Props> = ({ products, title, nested }) => {
  const height = 280;
  const { isLaptop } = useBreakpoints();
  const spaceBetween = isLaptop ? 10 : 40;

  React.useEffect(() => {
    sendAnalyticsOfItemList({
      products,
      eventType: 'view_item_list',
      title,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  if (products.length === 0) return null;

  return (
    <Container className={cx($.container, nested && $.containerNested)}>
      <h2 className={$.title}>{title}</h2>
      <SwiperCarousel
        className={$.swiper}
        height={height}
        slidesPerView="auto"
        spaceBetween={spaceBetween}
        nested
        centeredSlides
        useBaseStyle
        isLoop
      >
        {products.map((product, index) => (
          <SwiperSlide
            className={$.item}
            style={{ height }}
            key={product.productId}
          >
            <div className={$.item} key={product.productId}>
              <ProductBox product={product} index={index} carousel />
            </div>
          </SwiperSlide>
        ))}
      </SwiperCarousel>
    </Container>
  );
};

export default ProductsSwiper;

import {
  SizeModel,
  SizeModelConfigurable,
} from '~source/core/models/components/atoms/size';

export const calculateGridDetails = (
  sizes: (SizeModel | SizeModelConfigurable)[],
  isCompact: boolean,
): {
  rowsNumber: number;
  columnsNumber: 3 | 4 | 6;
} => {
  const hasLongText = sizes.some((size) => size.label.length > 4);

  const compactColumnSize = hasLongText ? 6 : 4;
  const columnsNumber = isCompact ? compactColumnSize : 3;

  const columnsPerRow = 12 / columnsNumber;
  const rowsNumber = Math.ceil(sizes.length / columnsPerRow);

  return {
    rowsNumber,
    columnsNumber,
  };
};

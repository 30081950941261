import cx from 'classnames';
import * as React from 'react';
import type {
  SizeModel,
  SizeModelConfigurable,
} from '~source/core/models/components/atoms/size';
import GridItem from '~source/ui/components/containers/grid-item/grid-item';
import Grid from '~source/ui/components/templates/grid/grid';
import { calculateGridDetails } from '~source/ui/utils/math/calculate-grid-details/calculate-grid-details';
import $ from './sizes-selector.module.scss';

type SizesSelectorProps = {
  sizes: (SizeModel | SizeModelConfigurable)[];
  selected: number | null;
  onChange: (size: number) => void;
  style?: 'default' | 'brand';
  isCompact?: boolean;
  isModal?: boolean;
};

const SizesSelector: React.FC<SizesSelectorProps> = ({
  sizes,
  selected,
  onChange,
  style = 'default',
  isCompact = false,
  isModal = false,
}) => {
  const { rowsNumber, columnsNumber } = calculateGridDetails(sizes, isCompact);

  return (
    <Grid
      wrapperElement={<ul role="radiogroup" />}
      className={cx($.grid, {
        [$.isCompact]: isCompact,
        [$.styleDefault]: style === 'default',
        [$.styleBrand]: style === 'brand',
      })}
      style={{
        gridTemplateRows: `repeat(${Math.max(rowsNumber, 2)}, 1fr)`,
      }}
    >
      {sizes.map((size) => {
        const isDisabled =
          size.availability === 'unavailable' ||
          size.availability === 'pending';

        return (
          <GridItem key={size.id} wrapperElement={<li />} xs={columnsNumber}>
            <button
              type="button"
              onClick={() => onChange(size.id)}
              role="radio"
              aria-checked={selected === size.id}
              disabled={isModal ? false : isDisabled}
              className={cx($.size, {
                [$.isSelected]: selected === size.id,
              })}
            >
              {size.label}
            </button>
          </GridItem>
        );
      })}
    </Grid>
  );
};

export default SizesSelector;

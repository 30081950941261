import cx from 'classnames';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import SwiperCore from 'swiper';
import { Pagination, Autoplay, Scrollbar } from 'swiper/modules';
import { Swiper } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';
import { BREAK_TABLET_VALUE, GUTTER_PHONE_VALUE } from '~source/constants';
import Container from '~source/ui/components/containers/container/container';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import debounce from '~source/ui/utils/debounce';
import $ from './swiper-carousel.module.scss';

// install Swiper modules
// NOTE: if we want to go back to the Scrollbar we need to import it
//   --: the imported scrollbar needs to be added to the SwiperCore
SwiperCore.use([Pagination, Autoplay, Scrollbar]);

interface Props {
  height?: number | string;
  className?: string;
  slidesPerView?: number | 'auto' | undefined;
  children?: React.ReactNode | React.ReactNode[];
  // eslint-disable-next-line react/no-unused-prop-types
  ref?:
    | ((instance: HTMLElement | null) => void)
    | React.MutableRefObject<HTMLElement | null>
    | null;
  nested?: boolean;
  useBaseStyle?: boolean;
  centeredSlides?: boolean;
  spaceBetween?: number;
  isFullWidth?: boolean;
  isNavigation?: boolean;
  isScrollable?: boolean;
  isLoop?: boolean;
  isPagination?: boolean;
  isPaginationVisible?: boolean;
  breakpoints?:
    | {
        [width: number]: SwiperOptions;
      }
    | undefined;
}

const SwiperCarousel: React.FC<Props> = forwardRef<HTMLElement | null, Props>(
  (
    {
      children,
      slidesPerView = 'auto',
      height = 'auto',
      className = '',
      nested = false,
      useBaseStyle = false,
      centeredSlides = false,
      spaceBetween = 10,
      isFullWidth = false,
      isNavigation = false,
      isScrollable = false,
      isLoop = false,
      isPagination = true,
      isPaginationVisible = false,
      breakpoints,
    },
    ref,
  ) => {
    if (typeof ref === 'function') {
      // eslint-disable-next-line no-param-reassign
      ref = null;
    }

    const isRelativeHeight = typeof height === 'string';
    const baseHeight = isRelativeHeight ? height : height + 20;

    const swiperRef = React.useRef<SwiperCore | null>(null);
    const [activeBtnIndex, setActiveBtnIndex] = useState<number>(0);
    const baseStyle = useMemo(() => ({ height: baseHeight }), [baseHeight]);
    const [style, setStyle] = useState<React.CSSProperties>(baseStyle);
    const t = useTranslate();

    const navigateSlide = React.useCallback((index: number) => {
      const swiper = swiperRef.current;
      if (!swiper) return;

      swiper.slideTo(index);
    }, []);

    const handlePrev = React.useCallback(() => {
      const swiper = swiperRef.current;
      if (!swiper) return;

      swiper.slidePrev();
    }, []);

    const handleNext = React.useCallback(() => {
      const swiper = swiperRef.current;
      if (!swiper) return;

      swiper.slideNext();
    }, []);

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      if (!nested || isFullWidth) return;
      let isMounted = true;

      const handler = debounce(() => {
        let width = document.body.clientWidth;
        if (isMounted && width < 1240) {
          let gutter = 0;

          if (width <= BREAK_TABLET_VALUE) {
            gutter = GUTTER_PHONE_VALUE * 2;
          }

          width -= gutter;
          const newStyle = { ...style, width };
          setStyle(newStyle);
        }

        if (
          isMounted &&
          typeof style.width === 'number' &&
          style.width >= 1240
        ) {
          setStyle(baseStyle);
        }
      }, 300);

      handler();

      window.addEventListener('resize', handler);
      // eslint-disable-next-line consistent-return
      return () => {
        isMounted = false;
        window.removeEventListener('resize', handler);
      };
    }, [setStyle, nested, style, baseStyle, useBaseStyle, isFullWidth]);

    const horizontalClass = isFullWidth ? $.scrollbarFlexWidth : $.scrollbar;

    return (
      <Container
        ref={ref}
        style={useBaseStyle ? baseStyle : style}
        className={cx(className, isPagination && $.container, $.padding)}
        isFullWidth={isFullWidth}
      >
        <Container
          className={cx(nested ? $.padding : '', $.containerNested)}
          isFullWidth={isFullWidth}
        >
          <Swiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            onSlideChange={({ realIndex }) => setActiveBtnIndex(realIndex)}
            style={{
              height: baseHeight,
            }}
            centeredSlides={centeredSlides}
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            breakpoints={breakpoints}
            scrollbar={isScrollable && { horizontalClass, draggable: true }}
            updateOnWindowResize
            loop={isLoop}
            autoplay={false}
            autoHeight
          >
            {children}
          </Swiper>
          {isPagination && (
            <div
              className={cx(
                $.pagination,
                isRelativeHeight && $.paginationMargin,
                isPaginationVisible && $.paginationVisible,
              )}
            >
              {Array.from(
                { length: React.Children.count(children) },
                (_, i) => (
                  <button
                    key={i}
                    className={cx(
                      $.paginationBullet,
                      activeBtnIndex === i && $.paginationBulletActive,
                    )}
                    type="button"
                    aria-hidden="true"
                    onClick={() => navigateSlide(i)}
                  />
                ),
              )}
            </div>
          )}
          {isNavigation && (
            <>
              <div className={cx($.navigationBtn, $.navigationBtnLeft)}>
                <button
                  className={cx($.chevron, $.chevronLeft)}
                  type="button"
                  onClick={handlePrev}
                  aria-label={t('PAGINATION_PREVIOUS')}
                />
              </div>
              <div className={cx($.navigationBtn, $.navigationBtnRight)}>
                <button
                  className={cx($.chevron, $.chevronRight)}
                  type="button"
                  onClick={handleNext}
                  aria-label={t('PAGINATION_NEXT')}
                />
              </div>
            </>
          )}
        </Container>
      </Container>
    );
  },
);

export default SwiperCarousel;

import * as React from 'react';
import { BundleLineBase } from '~source/core/models/bundle-line-base';
import { Badge } from '~source/core/models/components/atoms/badge';
import {
  SizeModel,
  SizeModelConfigurable,
} from '~source/core/models/components/atoms/size';
import {
  Printing,
  PrintingType,
  SizelessProduct,
} from '~source/core/models/components/organisms/product-details';
import getProductRequirementValuesForOrder from '~source/core/services/eva/api/order/get-product-requirement-values-for-order';
import { createProductRequirementsPayload } from '~source/core/services/eva/api/order/update-product-requirements-for-order';
import { useCart } from '~source/ui/hooks/cart/useCart/useCart';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import { useOrder } from '~source/ui/hooks/order/useOrder/useOrder';
import {
  isBundle,
  isConfigurable,
} from '~source/ui/utils/checks/is-product-type';
import getPrintingInfo from '~source/ui/utils/getters/get-printing-info';

const createConfigurablePayload = (
  selectedSizeId: number | null,
  sizes: (SizeModel | SizeModelConfigurable)[] | null,
) => {
  if (!selectedSizeId || !sizes) return null;

  const sizeProduct = sizes.find((size) => size.id === selectedSizeId);
  if (!sizeProduct) {
    console.error(`No size matched: ${selectedSizeId}`);
    return null;
  }
  return {
    id: sizeProduct.id,
  };
};

const createLines = ({
  selectedSizeIds,
  selectedBadge,
  printing,
  printingOptionsInfo,
  backNumber,
  requiredLogo,
  sizes,
  printingType,
  sizelessProducts,
}: {
  selectedSizeIds: number[];
  selectedBadge: Badge | null;
  backNumber: string | null;
  printing: string | null;
  printingOptionsInfo: Printing[] | null;
  printingType: PrintingType;
  requiredLogo: Badge | null;
  sizes: (SizeModel | SizeModelConfigurable)[] | null;
  sizelessProducts: SizelessProduct[] | null;
}) => {
  const lines: BundleLineBase[] = [];

  if (selectedSizeIds.length && sizes) {
    selectedSizeIds.forEach((selectedSizeId) => {
      const sizeProduct = sizes.find((size) => size.id === selectedSizeId) as
        | SizeModel
        | undefined;
      if (sizeProduct && 'productBundleLineId' in sizeProduct) {
        lines.push(sizeProduct);
      }
    });
  }

  if (sizelessProducts?.length) {
    sizelessProducts.forEach((sizelessProduct) => {
      lines.push(sizelessProduct);
    });
  }

  if (selectedBadge) {
    lines.push(selectedBadge);
  }

  if (printing && printingOptionsInfo) {
    const printInfo = getPrintingInfo(
      printingType,
      'letters',
      printingOptionsInfo,
    );

    if (printInfo) {
      lines.push(printInfo);
    }
  }
  if (backNumber && printingOptionsInfo) {
    const printInfo = getPrintingInfo(
      printingType,
      'number',
      printingOptionsInfo,
    );

    if (printInfo) {
      lines.push(printInfo);
    }
  }

  if (backNumber && printing && requiredLogo) {
    lines.push(requiredLogo);
  }

  return lines;
};

export type AsyncStatus = 'idle' | 'loading' | 'error' | 'success';

export default function useAddProductToOrder({
  productId,
  productTypes,
  sizes,
  selectedSizeIds,
  printingOptionsInfo = null,
  printingType = 'none',
  printing = null,
  selectedBadge = null,
  backNumber = null,
  requiredLogo = null,
  sizelessProducts = null,
}: {
  productId: number | null;
  productTypes: EVA.Core.ProductTypes[] | null;
  sizes: (SizeModel | SizeModelConfigurable)[] | null;
  printingOptionsInfo?: Printing[] | null;
  printingType?: PrintingType;
  printing?: string | null;
  selectedSizeIds: number[];
  selectedBadge?: Badge | null;
  backNumber?: string | null;
  requiredLogo?: Badge | null;
  sizelessProducts?: SizelessProduct[] | null;
}) {
  const [error, setError] = React.useState('');
  const t = useTranslate();
  const { getOrder } = useOrder();
  const { addProductToCart, removeProducts, addProductRequirements } =
    useCart();

  const [status, setStatus] = React.useState<AsyncStatus>('idle');

  const addBundleToOrder = async () => {
    if (!productId) return;

    const lines = createLines({
      selectedSizeIds,
      selectedBadge,
      printing,
      printingType,
      printingOptionsInfo,
      backNumber,
      requiredLogo,
      sizes,
      sizelessProducts,
    });

    const orderLineId = await addProductToCart({
      id: productId,
      lines: lines.length ? lines : undefined,
    });
    if (!orderLineId) {
      setError(t('ERROR_MESSAGE_UNKNOWN'));
      setStatus('error');
      return;
    }

    const orderId = await getOrder();

    const requirements = await getProductRequirementValuesForOrder({
      orderId,
    });
    if (!requirements) return;

    try {
      const requirementsForItem = requirements.filter(
        (requirement) => requirement.id > orderLineId,
      );
      const requirementsList = createProductRequirementsPayload({
        printingOptionsInfo,
        printing,
        backNumber,
        printingType,
        requirements: requirementsForItem,
      });
      requirementsList.forEach(async (requirement) => {
        addProductRequirements(requirement);
      });
      setStatus('success');
    } catch {
      // If the requirements call fails, remove the shirt from the cart
      const ids = requirements.map((requirement) => requirement.id);
      await removeProducts({ orderLineIds: [...ids, orderLineId] });
      setError(t('ERROR_MESSAGE_UNKNOWN'));
      setStatus('error');
    }
  };

  const addConfigurableToOrder = async () => {
    // Configurable products only have one size selected
    const payload = createConfigurablePayload(selectedSizeIds[0], sizes);
    if (!payload) {
      setStatus('error');
      return;
    }
    await addProductToCart(payload);
  };

  const addToBasket = async (): Promise<void> => {
    if (!productId) {
      setError(t('ERROR_MESSAGE_NO_PRODUCT_SELECTED'));
      return;
    }
    if (!productTypes) return;
    setError('');
    setStatus('loading');

    try {
      if (isBundle(productTypes)) {
        await addBundleToOrder();
      } else if (isConfigurable(productTypes)) {
        await addConfigurableToOrder();
      } else
        await addProductToCart({
          id: productId,
        });
      setStatus('success');
    } catch {
      setError('ERROR_MESSAGE_UNKNOWN');
      setStatus('error');
    }
  };
  return {
    addToBasket,
    error,
    status,
  };
}

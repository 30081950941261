/**
 * Constrains a value between a minimum and maximum value.
 * @param  {Number} input number to constrain
 * @param  {Number} low   minimum limit
 * @param  {Number} high  maximum limit
 * @return {Number}       constrained number
 */
const constrain = (input: number, low: number, high: number): number =>
  Math.max(Math.min(input, high), low);

export default constrain;

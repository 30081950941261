import * as React from 'react';
import sanitizeHtml from 'sanitize-html';

type Props = {
  // eslint-disable-next-line no-undef
  container: JSX.Element;
  html: string;
};

export default function InnerHtml({ html, container = <div /> }: Props) {
  const sanitizedHtml = React.useMemo(() => {
    return sanitizeHtml(html);
  }, [html]);

  return React.cloneElement(container, {
    dangerouslySetInnerHTML: {
      __html: sanitizedHtml,
    },
    children: null, // clear any potential children to remove potential errors as `dangerouslySetInnerHTML` will overrule them
  });
}

import cx from 'classnames';
import * as React from 'react';
import $ from './skeleton-box.module.scss';

interface Props {
  className?: string;
  displayBlock?: boolean;
  height?: number | string;
  width?: number | string;
  marginBottom?: number;
}

const SkeletonBox: React.FunctionComponent<Props> = ({
  height,
  marginBottom,
  width,
  displayBlock,
  className,
}) => {
  return (
    <div
      className={cx($.container, displayBlock && $.containerBlock, className)}
      style={{ height, marginBottom, width }}
    />
  );
};

export default SkeletonBox;

import {
  Printing,
  PrintingType,
} from '~source/core/models/components/organisms/product-details';

export default function getPrintingInfo(
  printingType: PrintingType,
  printOption: 'number' | 'letters',
  printingOptionsInfo?: Printing[] | null,
) {
  if (!printingOptionsInfo) return null;
  const isPlayer = printingType === 'player';
  const info = isPlayer
    ? printingOptionsInfo.find(
        (option) => option.isPlayer && option.printing === printOption,
      )
    : printingOptionsInfo.find(
        (option) => !option.isPlayer && option.printing === printOption,
      );
  return info;
}

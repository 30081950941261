import { useEffect, useState, useRef } from 'react';

export default function useTimer(ms: number) {
  const [isTimerActive, setIsTimerActive] = useState(false);
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const setTimer = () => {
    timeoutIdRef.current = null;
    setIsTimerActive(true);
    timeoutIdRef.current = setTimeout(() => setIsTimerActive(false), ms);
  };

  const cancelTimer = () => {
    timeoutIdRef.current = null;
    setIsTimerActive(false);
  };

  useEffect(
    () => () => cancelTimer(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    setTimer,
    cancelTimer,
    isTimerActive,
  };
}

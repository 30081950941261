/* eslint-disable no-undef */
export default function setDYContext(
  type: string,
  lng: string,
  data?: string[],
) {
  if (typeof window === 'undefined') return;
  // @ts-ignore dynamic yield will be set on the window object
  window.DY = window.DY || {};

  const languageWithUnderscore = lng.replace('-', '_');
  const partialCtx = { type, lng: languageWithUnderscore };
  // @ts-ignore DY will work according to the documentation
  if (!data) DY.recommendationContext = partialCtx;
  // @ts-ignore DY will work according to the documentation
  DY.recommendationContext = { ...partialCtx, data };
}
